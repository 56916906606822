<template>
	<div>
		<div class="row mb-2 no-gutters">
			<div
				v-if="!addressLoading"
				class="col">
				<div
					class="px-2 pb-1">
					<div
						class="row px-1">
						<div class="col-12">
							<address-radio-button
								class="mw-100"
								show-gift-addresses
								:show-normal-addresse="false"
								show-pickup-addresses
								:address-data="visibleAddresses"
								:address-loading="addressLoading"
								:form-import="form"
								:hide-new-address="addAddress"
								:display-add-new-address="false"
								:borderless="true"
								@add-address="addressFormState" />
							<div
								v-if="!addAddress"
								class="row">
								<div class="col-12 px-4">
									<hr class="mt-0">
								</div>
							</div>
							<div
								v-if="addAddress"
								class="pt-3 px-2">
								<address-country-form
									:address="ADDRESSES.shipping"
									:user-country="true"
									:form-import="form"
									:show-address-agreement="false"
									:errors-import="addressErrors.errors"
									:country-code-import="initialCountryCode"
									:prefix-errors="''"
									:display-cancel-button="false"
									:display-submit-button="false"
									:display-goback-button="false"
									:display-override-toggle="showOverrideValidation"
									hide-country-field />
							</div>
						</div>
					</div>
					<div
						class="row px-2">
						<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mr-auto">
							<button
								v-if="addAddress && hasAddressData"
								:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
								class="btn btn-link b-4 float-left"
								@click="addressFormState">
								{{ translate('see_address_list') }}
							</button>
							<button
								v-else-if="!addAddress"
								:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
								class="btn btn-link b-4 float-left"
								@click="addressFormState">
								{{ translate('use_another_address') }}
							</button>
						</div>
					</div>
					<div
						v-if="(typeof validationErrors['shipping.shipping_address.address_id'] !== 'undefined')"
						class="row p-2">
						<div
							id="address"
							class="col-12">
							<b-alert
								variant="danger"
								show>
								<div
									v-for="error in validationErrors['shipping.shipping_address.address_id']"
									:key="error">
									<span
										v-text="error" />
									<br>
								</div>
							</b-alert>
						</div>
					</div>
					<div class="row px-2">
						<!-- NEW ADDRESS BUTTONS -->
						<div
							v-if="addAddress"
							:class="['xs', 'sm'].includes(windowWidth) ? 'mt-3' : 'ml-auto'"
							class="col-12 col-md-auto">
							<div class="row no-gutters justify-content-end">
								<div
									v-if="hasAddressData"
									:class="['xs', 'sm'].includes(windowWidth) ? 'mt-1' : 'mr-2'"
									class="col order-2 order-md-1">
									<b-button
										:disabled="createAddressLoading"
										style="min-width: 150px"
										class="w-100"
										variant="light"
										size="lg"
										@click="addressFormState">
										{{ translate('cancel') }}
									</b-button>
								</div>
								<div
									:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
									class="col col-md-6 order-1 order-md-2">
									<b-button
										class="w-100"
										style="min-width: 200px"
										variant="primary"
										size="lg"
										@click="newAddress(form)">
										{{ translate('save_address_button') }}
									</b-button>
								</div>
							</div>
						</div>
						<!-- MAIN BUTTONS -->
						<div
							v-if="!addAddress"
							:class="['xs', 'sm'].includes(windowWidth) ? 'mt-3' : 'ml-auto'"
							class="col-12 col-md-auto">
							<div class="row no-gutters justify-content-end">
								<div
									:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
									class="col col-md-6 order-1 order-md-2">
									<b-button
										:disabled="form.address_id === 0 || createAddressLoading"
										style="min-width: 200px"
										class="w-100"
										variant="primary"
										size="lg"
										@click="saveAsSelectedAddress()">
										<i
											v-if="createAddressLoading"
											class="fas fa-spinner fa-spin mr-2" />
										{{ translate('continue') }}
									</b-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				v-else
				class="col-12 mt-2 fade-in text-center d-flex justify-content-center align-items-center">
				<div class="col-12">
					<div class="h2">
						<i class="fa fa-fw fa-spinner fa-pulse" />
						<h4 class="mt-3">
							{{ translate('loading') }}
						</h4>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import AddressRadioButton from '@/components/AddressRadioButton';
import AddressCountryForm from '@/components/AddressCountryForm';
import WindowSizes from '@/mixins/WindowSizes';
import {
	Addresses as AddressesMessages, Countries as CountriesMessages, Purchase as PurchaseMessages, Validations as ValidationMessages,
} from '@/translations';
import {
	UNPROCESSABLE, SYSTEM_ALERT,
} from '@/settings/Errors';
import AddressBook from '@/util/AddressBook';
import Addresses from '../../mixins/Addresses';
import CommonMix from '../../mixins/Common';
import { ADDRESSES } from '@/settings/AddressBook';

export default {
	name: 'ShippingEdit',
	messages: [AddressesMessages, PurchaseMessages, ValidationMessages, CountriesMessages],
	components: {
		AddressRadioButton,
		AddressCountryForm,
	},
	mixins: [Addresses, CommonMix, WindowSizes],
	data() {
		return {
			createAddress: new AddressBook(),
			form: {
				first_name: '',
				last_name: '',
				mobile_number: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				country_code: '',
				region_id: '',
				signature_required: false,
				override_validation: false,
				address_id: 0,
				personal_document_number: '',
				personal_document_front_file: null,
				personal_document_back_file: null,
				disclaimer: 0,
			},
			alert: new this.$Alert(),
			addAddress: false,
			showOverrideValidation: false,
			visibleAddresses: [],
			ADDRESSES,
			addressType: 'shipping',
		};
	},
	computed: {
		createAddressLoading() {
			return this.createAddress.data.loading;
		},
	},
	watch: {
		hasAddressData() {
			this.showAddAddressIfNonAreAvailable();
		},
		addressLoading() {
			this.showAddAddressIfNonAreAvailable();
		},
		addressData() {
			this.reloadAddress();
		},
		initialCountryCode: {
			immediate: true,
			handler(newVal) {
				this.form.country_code = newVal;
				this.refreshAddressBook();
				this.refreshStates();
			},
		},
	},
	created() {
		this.initializeValues();
	},
	methods: {
		initializeValues() {
			this.form.country_code = this.initialCountryCode;
		},
		saveAsSelectedAddress() {
			const addressId = this.form.address_id;
			const isPickup = this.visibleAddresses.find((address) => address.id === addressId).attributes.is_pickup_address;
			this.$emit('address-selected', { address_id: this.form.address_id, is_pickup: isPickup });
		},
		showAddAddressIfNonAreAvailable() {
			if (!this.addressLoading && !this.addAddress && !this.hasAddressData) {
				this.addAddress = true;
			}
		},
		clearAddressForm() {
			this.form.first_name = '';
			this.form.last_name = '';
			this.form.mobile_number = '';
			this.form.address = '';
			this.form.address2 = '';
			this.form.city = '';
			this.form.postcode = '';
			this.form.country_code = this.initialCountryCode;
			this.form.region_id = '';
			this.form.override_validation = false;
			this.form.personal_document_number = '';
			this.form.personal_document_front_file = null;
			this.form.personal_document_back_file = null;
			this.showOverrideValidation = false;
		},
		addressFormState() {
			if (!this.addAddress) {
				this.clearAddressForm();
			}
			if (Object.keys(this.addressErrors.errors).length > 0) {
				const errorFields = Object.keys(this.addressErrors.errors);
				errorFields.forEach((field) => {
					delete this.addressErrors.errors[field];
					this.addressErrors.errors = { ...this.addressErrors.errors };
				});
			}
			this.addAddress = !this.addAddress;
		},
		newAddress(form) {
			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), options);

			const payload = { ...form };
			if (!this.showOverrideValidation) {
				payload.override_validation = undefined;
			}

			payload.gift_address = true;

			this.createAddress.saveAddress(this.$user.details().id, this.objectToFormData(payload)).then(() => {
				this.alert.toast('success', this.translate('address_created'), { timer: 6000 });

				this.refreshAddressBook().then((response) => {
					if (response.length) {
						const lastAddressPos = response.length - 1;
						this.form.address_id = response[lastAddressPos].id;
					}
				}).then(() => this.saveAsSelectedAddress()).finally(() => { this.addAddress = false; });
			}).catch(() => {
				if (UNPROCESSABLE.includes(this.addressErrors.status)) {
					Object.keys(this.addressErrors.errors).forEach((errorKey) => {
						if (errorKey === 'suggestion') {
							this.showSuggestionAlert(form);
						} else if (
							errorKey === 'address'
							&& !!this.form.address.trim().length
							&& this.addressVerificationCountries.includes(this.form.country_code)
						) {
							this.showOverrideValidation = true;
						}
					});
				}
				if (SYSTEM_ALERT.includes(this.addressErrors.status) || this.addressErrors.status === undefined) {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				} else {
					this.alert.close();
				}
			});
		},
		showSuggestionAlert(form) {
			this.getSuggestion.getSuggestion(form).then((suggestionResponse) => {
				let address = '';
				const suggestedAddress = suggestionResponse.response.address;

				Object.keys(suggestedAddress).forEach((item, index) => {
					if (suggestedAddress[item] !== '' && item !== 'region_id') {
						address += `${suggestedAddress[item]}`;
						if (index < (Object.keys(suggestedAddress).length) - 2) {
							address += ', ';
						}
					}
				});
				const trans = {
					title: this.translate('invalid_address'),
					text: this.translate('suggestion_address', { address }),
				};
				const options = {
					config: {
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: this.translate('accept_sugestion'),
						cancelButtonText: this.translate('manual_change'),
						reverseButtons: true,
						allowOutsideClick: false,
						allowEscapeKey: false,
					},
				};
				this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
					Object.keys(suggestedAddress).forEach((suggestionItem) => {
						Object.keys(this.form).forEach((formItem) => {
							if (formItem === suggestionItem) {
								this.form[formItem] = suggestedAddress[suggestionItem];
							}
						});
					});
					this.newAddress(this.form);
				}).catch(() => {});
			}).catch(() => {});
		},
		reloadAddress() {
			this.visibleAddresses = this.addressData.filter((address) => address.attributes.is_pickup_address || address.attributes.is_gift_address).map((address) => ({ ...address }));
		},
	},
};
</script>
