import {
	CREATE_GIFT_ORDER_CART, CREATE_GIFT_ORDER_PACK_CART,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class GiftOrderCart {
	constructor() {
		this.data = new Req();
		this.errors = this.data.errors;
	}

	clear() {
		this.data.clear();
	}

	createGiftOrderCart(payload) {
		const { method, endpoint } = CREATE_GIFT_ORDER_CART;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createGiftOrderPackCart(payload) {
		const { method, endpoint } = CREATE_GIFT_ORDER_PACK_CART;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default GiftOrderCart;
