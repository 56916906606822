<template>
	<div>
		<b-row>
			<b-col cols="12">
				<table class="table table-borderless">
					<tr
						v-for="product in packData.products_pack"
						:key="product.id">
						<td width="20%" />
						<td class="p-1 text-center align-middle">
							<img
								v-if="product.activation_weeks"
								:src="product.image"
								width="50px"
								class="img-fluid">
							<img
								v-else
								:src="product.children[0].image"
								width="50px"
								class="img-fluid">
						</td>
						<td class="p-1 align-middle">
							<span
								v-if="product.activation_weeks"
								v-html="translate('activation_weeks_choice', {activation_weeks: product.activation_weeks})" />
							<template v-else>
								<strong>
									{{ product.children[0].qty }} x
								</strong>
								{{ translate(product.children[0].code_name) }}
							</template>
						</td>
					</tr>
				</table>
			</b-col>
			<b-col cols="2" />
			<b-col cols="8">
				<number-input-spinner
					v-model="packQty"
					class="success"
					:mouse-down-speed="500"
					:min="1"
					:max="packData.max_purchase_qty"
					:button-class="'vnis__button bg-primary-alt'"
					:integer-only="true" />
			</b-col>
		</b-row>
	</div>
</template>
<script>
import NumberInputSpinner from 'vue-number-input-spinner';
import { Purchase, Products } from '@/translations';

export default {
	name: 'MultiplePurchasePack',
	messages: [Purchase, Products],
	components: {
		NumberInputSpinner,
	},
	props: {
		packData: {
			type: Object,
			default: () => {},
		},
		selectedPack: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			packQty: 0,
		};
	},
	watch: {
		packQty: {
			handler() {
				this.$emit('update:packQty', this.packQty);
				const disableConfirm = this.packQty > this.packData.max_purchase_qty || this.packQty < 1;
				this.$emit('toggleDisableConfirm', disableConfirm, this.selectedPack);
			},
			immediate: true,
		},
	},
};
</script>
