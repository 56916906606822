export default {
	props: {
		packageCalculation: {
			type: Object,
			required: true,
		},
		fullyCustomizable: {
			type: Boolean,
			default: false,
		},
		fullyAmountCustomizable: {
			type: Boolean,
			default: false,
		},
		minimumAmountCustomizable: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		// Package Calculation
		calculatedAmountProgress() {
			try {
				return this.packageCalculation.data.response.data.response.product.total;
			} catch (error) {
				return 0;
			}
		},
		calculatedAmountProgressFormatted() {
			try {
				return this.packageCalculation.data.response.data.response.product.total_formatted;
			} catch (error) {
				return 0;
			}
		},
		packDiscount() {
			try {
				return this.packageCalculation.data.response.data.response.product.discount_text;
			} catch (error) {
				return null;
			}
		},
		packTotalWithDiscount() {
			try {
				return this.packageCalculation.data.response.data.response.product.total_with_discount_formatted;
			} catch (error) {
				return null;
			}
		},
		packPrice() {
			try {
				return this.packageCalculation.data.response.data.response.product.pack_price;
			} catch (error) {
				return 0;
			}
		},
		formattedPackPrice() {
			try {
				return this.packageCalculation.data.response.data.response.product.pack_price_formatted;
			} catch (error) {
				return 0;
			}
		},
		calculatedVolumeProgress() {
			try {
				return this.packageCalculation.data.response.data.response.product.bvs;
			} catch (error) {
				return 0;
			}
		},
		upgradeText() {
			try {
				return this.packageCalculation.data.response.data.response.product.upgraded_message;
			} catch (error) {
				return null;
			}
		},
		isAllowedAmount() {
			try {
				return this.packageCalculation.data.response.data.response.product.is_allowed_amount;
			} catch (error) {
				return false;
			}
		},
		exceedsAllowedAmount() {
			try {
				return this.packageCalculation.data.response.data.response.product.exceeds_allowed_amount;
			} catch (error) {
				return false;
			}
		},
		isCalculationLoading() {
			return !!this.packageCalculation.data.loading;
		},
	},
};
