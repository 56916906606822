<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row">
			<div class="col-12 w-100 d-flex justify-content-end">
				<!-- Discounts -->
				<div
					v-for="(item, index) in discounts"
					:key="index"
					:style="{
						width: `${item.width}%`,
						fontSize: currentProgress >= item.min ? '20px' : ''
					}"
					:class="{
						'text-muted': currentProgress < item.min,
						'text-discount': currentProgress >= item.min,
					}"
					class="p-0 d-flex flex-column align-items-end font-weight-bold"
					style="align-self: flex-end;">
					${{ item.discount }}
					<i
						class="fas fa-caret-up fa-lg text-lime" />
				</div>
			</div>
			<!-- If has a specific discount, show the text of the discount -->
			<div
				v-if="packDiscount"
				class="col-12 w-100 d-flex justify-content-center align-items-center text-center">
				<h6
					class="text-discount text-center mt-1 font-italic"
					v-html="packDiscount" />
			</div>
			<!-- If is full customizable, show progress by by bvs -->
			<div
				v-if="fullyCustomizable"
				class="col-12">
				<b-progress
					:max="packVolume"
					precision="0.01"
					:animated="true"
					:variant="progressVariant"
					height="30px"
					striped
					class="mt-auto">
					<b-progress-bar
						:value="currentVolumeProgress"
						:class="progressClass">
						<strong style="width: 100%; position: absolute; font-size: 1.7em;">
							<span class="text-dark bg-light h-100 rounded px-3">
								{{ currentVolumeProgress + ' / ' + packVolume + ' ' + translate('bv') }}
							</span>
						</strong>
					</b-progress-bar>
				</b-progress>
				<h6
					v-if="upgradeText"
					class="text-discount text-center mt-1">
					{{ upgradeText }}
				</h6>
			</div>
			<!-- Progress bar if pack has to reach an amount -->
			<div
				v-else-if="fullyAmountCustomizable || minimumAmountCustomizable"
				class="col-12">
				<b-progress
					:max="packPrice"
					precision="0.01"
					:animated="true"
					height="30px"
					striped
					class="mt-auto">
					<b-progress-bar
						:value="currentAmountProgress"
						:class="progressAmountClass">
						<strong style="width: 100%; position: absolute; font-size: 1.7em;">
							<span class="text-dark bg-light h-100 rounded px-3">
								{{ calculatedAmountProgressFormatted }}  /  {{ formattedPackPrice }}
							</span>
						</strong>
					</b-progress-bar>
				</b-progress>
			</div>
			<!-- If is full customizable (progress by bvs) show current price of selected products -->
			<div
				v-if="fullyCustomizable"
				class="col-12 text-right mt-1">
				<!--<h6>{{ translate('subtotal') }} {{ currentProgress | currency('usd', translate) }}</h6>-->
				<h3>{{ translate('total') }} {{ showAdditionalSign ? '+' : '' }} {{ currentProgress - discount | currency('usd', translate) }}</h3>
			</div>
			<!-- If package contains a discount, show the price that will be paid -->
			<div
				v-if="packDiscount && progressAmountClass !== '' && !exceedsAllowedAmount"
				class="col-12 text-right mt-1">
				<!--<h6>{{ translate('subtotal') }} {{ currentProgress | currency('usd', translate) }}</h6>-->
				<h3>{{ translate('total') }} {{ packTotalWithDiscount }}</h3>
			</div>
			<!-- If package has to reach a price, show bvs earned -->
			<div
				v-if="fullyAmountCustomizable"
				class="col-12 text-right mt-1">
				<!--<h6>{{ translate('subtotal') }} {{ currentProgress | currency('usd', translate) }}</h6>-->
				<h3>{{ showAdditionalSign ? '+' : '' }} {{ currentVolumeProgress + ' ' + translate('bv') }}</h3>
			</div>
		</div>
	</div>
</template>
<script>
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import WindowSizes from '@/mixins/WindowSizes';
import { Products, Purchase, Promotions } from '@/translations';
import { currency } from '@/config/Filters';
import PackageCalculation from '@/mixins/PackageCalculation';
import { AUTOSHIP_MIN_QTY } from '@/settings/Wizard';

export default {
	name: 'ProgressBars',
	messages: [Products, Purchase, Promotions],
	filters: {
		currency,
	},
	mixins: [ConfigurableProducts, WindowSizes, PackageCalculation],
	props: {
		discounts: {
			type: Array,
			default: () => [],
		},
		packVolume: {
			type: Number,
			default: 0,
		},
		products: {
			type: Array,
			default: () => [],
		},
		selectedPack: {
			type: String,
			default: '',
		},
		sponsorId: {
			type: Number,
			default: 0,
		},
		selectedProducts: {
			type: Object,
			default: () => ({}),
		},
		showAdditionalSign: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		// Progress
		currentAmountProgress() {
			if (this.fullyCustomizable || this.fullyAmountCustomizable || this.minimumAmountCustomizable) {
				return this.calculatedAmountProgress.toFixed(2);
			}

			let productsAmount = 0;
			Object.entries(this.selectedProducts).forEach(([key, value]) => {
				let productAmount = 0;
				this.products.forEach((product) => {
					if (product.attributes.sku === key) {
						productAmount = product.attributes.price.min_price;
						return;
					}

					Object.keys(product.attributes.children).forEach((sku) => {
						if (sku === key) {
							productAmount = product.attributes.children[sku].price.min_price;
						}
					});
				});

				productsAmount += value.value * productAmount;
			});
			return productsAmount.toFixed(2);
		},
		currentVolumeProgress() {
			if (this.fullyCustomizable || this.fullyAmountCustomizable || this.minimumAmountCustomizable) {
				return this.calculatedVolumeProgress;
			}

			let productsVolume = 0;
			Object.entries(this.selectedProducts).forEach(([key, value]) => {
				let productVolume = 0;
				this.products.forEach((product) => {
					if (product.attributes.sku === key) {
						productVolume = product.attributes.bvs;
						return;
					}

					Object.keys(product.attributes.children).forEach((sku) => {
						if (sku === key) {
							productVolume = product.attributes.children[sku].bvs;
						}
					});
				});

				productsVolume += value.value * productVolume;
			});
			return productsVolume;
		},
		currentProgress() {
			const amount = this.packPrice;
			let extraProductsAmount = 0;
			const productsWithDiscount = [];
			let productWithTenDiscount = 0;
			Object.entries(this.selectedProducts).forEach(([key, value]) => {
				let productPrice = 0;
				this.products.forEach((product) => {
					if (product.attributes.sku === key) {
						productPrice = product.attributes.price.min_price;
						return;
					}

					Object.keys(product.attributes.children).forEach((sku) => {
						if (sku === key) {
							productPrice = product.attributes.children[sku].price.min_price;
						}
					});
				});

				if (productPrice > 0 && productsWithDiscount.some((prefix) => key.startsWith(prefix))) {
					productWithTenDiscount += value.value;
				}
				extraProductsAmount += value.value * productPrice;
			});
			extraProductsAmount -= 10 * productWithTenDiscount;
			return amount + extraProductsAmount;
		},
		progressVariant() {
			return this.currentVolumeProgress >= this.packVolume ? 'secondary' : 'primary';
		},
		progressClass() {
			return this.currentVolumeProgress >= this.packVolume ? 'success-background' : '';
		},
		progressAmountClass() {
			// eslint-disable-next-line no-nested-ternary
			return this.exceedsAllowedAmount ? 'error-background' : (this.currentAmountProgress >= this.packPrice ? 'success-background' : '');
		},
		// Discount
		discount() {
			return [...this.discounts].reverse().find((item) => this.currentProgress >= item.min)?.discount ?? 0;
		},
		// Front
		disableConfirm() {
			if (this.fullyCustomizable) {
				return this.currentVolumeProgress < this.packVolume;
			}
			if (this.fullyAmountCustomizable || this.minimumAmountCustomizable) {
				return !this.isAllowedAmount || this.exceedsAllowedAmount;
			}
			const selectedQty = Object.values(this.selectedProducts).reduce((accum, product) => accum + product.value, 0);
			const minQty = AUTOSHIP_MIN_QTY[this.country];
			return selectedQty < minQty;
		},
	},
	watch: {
		disableConfirm: {
			handler() {
				this.$emit('toggleDisableConfirm', this.disableConfirm, this.selectedPack);
			},
			immediate: true,
		},
	},
};
</script>
