<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<b-row>
			<b-col cols="12">
				<products-section
					:loading-products="loading"
					:products="products"
					:pack-volume="packData.pack_volume"
					:fully-customizable="packData.fully_customizable"
					:fully-amount-customizable="packData.fully_amount_customizable"
					:minimum-amount-customizable="packData.minimum_amount_customizable"
					:initial-selected-products="initialSelectedProducts"
					:selected-pack="selectedPack"
					:is-register="isRegister"
					:loading-save="loadingSave"
					:package-calculation="packageCalculation"
					@update:selectedProducts="updateSelectedProducts($event)" />
			</b-col>
			<b-col cols="12 mt-2">
				<progress-bars
					:discounts="[]"
					:fully-customizable="isFullyCustomizedPack"
					:fully-amount-customizable="fullyAmountCustomizable"
					:minimum-amount-customizable="isMinimumAmountCustomizedPack"
					:pack-volume="selectedPack.pack_volume"
					:loading-products="false"
					:products="products"
					:selected-pack="selectedPack"
					:package-calculation="packageCalculation"
					:selected-products="selectedProducts"
					@toggleDisableConfirm="handleDisableConfirm" />
			</b-col>
		</b-row>
	</div>
</template>
<script>
import ProgressBars from '@/components/AdditionalProductsModal/ProgressBars';
import ProductsSection from '@/components/AdditionalProductsModal/ProductsSection';
import GeneralInformation from '@/util/GeneralInformation';
import { AUTOSHIP_MIN_QTY } from '@/settings/Wizard';

export default {
	name: 'ProductListPack',
	components: {
		ProductsSection,
		ProgressBars,
	},
	props: {
		packData: {
			type: Object,
			default: () => ({}),
		},
		products: {
			type: Array,
			default: () => [],
		},
		selectedPack: {
			type: String,
			default: '',
		},
		isRegister: {
			type: Boolean,
			default: false,
		},
		loadingSave: {
			type: Boolean,
			default: false,
		},
		initialSelectedProducts: {
			type: Object,
			default: () => ({}),
		},
		cartCountry: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			selectedProducts: this.initialSelectedProducts,
			loading: false,
			showBackorderInfo: false,
			description: '',
			packageCalculation: new GeneralInformation(),
		};
	},
	computed: {
		// From packData var
		isFullyCustomizedPack() {
			return this.packData.fully_customizable;
		},
		fullyAmountCustomizable() {
			return this.packData.fully_amount_customizable;
		},
		isMinimumAmountCustomizedPack() {
			return this.packData.minimum_amount_customizable;
		},
		// Package Calculations
		isAllowedAmount() {
			try {
				return this.packageCalculation.data.response.data.response.product.is_allowed_amount;
			} catch (error) {
				return false;
			}
		},
		exceedsAllowedAmount() {
			try {
				return this.packageCalculation.data.response.data.response.product.exceeds_allowed_amount;
			} catch (error) {
				return false;
			}
		},
		// Front actions
		disableActions() {
			if (this.isFullyCustomizedPack) {
				return false; // this.currentVolumeProgress < this.packVolume;
			}
			if (this.fullyAmountCustomizable || this.minimumAmountCustomizable) {
				return !this.isAllowedAmount || this.exceedsAllowedAmount;
			}
			const selectedQty = Object.values(this.selectedProducts).reduce((accum, product) => accum + product.value, 0);
			const minQty = AUTOSHIP_MIN_QTY[this.country];
			return selectedQty < minQty;
		},
	},
	watch: {
		selectedPack: {
			handler() {
				this.updateSelectedProducts(this.selectedProducts);
			},
			deep: true,
		},
	},
	mounted() {
		this.updateSelectedProducts(this.selectedProducts);
	},
	methods: {
		updateSelectedProducts(selectedProducts) {
			this.selectedProducts = selectedProducts;
			if (this.isFullyCustomizedPack || this.fullyAmountCustomizable || this.isMinimumAmountCustomizedPack) {
				const extraProducts = Object.entries(this.selectedProducts).reduce((accumulator, [key, value]) => {
					if (value.value > 0) {
						accumulator[key] = value.value;
					}
					return accumulator;
				}, {});
				const options = {
					flow: 'purchase',
					country: this.cartCountry,
					package_code_name: this.selectedPack,
					products: extraProducts,
					sponsor: this.sponsorId,
				};
				this.packageCalculation.packsCalculation(options);
				this.$emit('updateSelection', extraProducts);
			}
		},
		handleDisableConfirm(disable, pack) {
			this.$emit('toggleDisableConfirm', disable, pack);
		},
	},
};
</script>
