<template>
	<div class="row align-items-center">
		<div class="col-12 col-md-6 h-100">
			<div class="row no-gutters align-items-center">
				<div
					:class="stackedLayout ? 'col-6' : 'col-9'"
					class="d-flex p-0 h-100 align-items-center">
					<div
						v-if="!stackedLayout"
						class="h-100 mr-2 align-items-center align-content-start">
						<img
							:src="thumbnail"
							class="h-100"
							style="margin-left: -15px; height: 2.325em !important;">
					</div>
					<span>
						{{ translate(codeName) }}
						<template v-if="backorderInfo && backorderInfo.will_backorder">
							<i
								v-b-tooltip
								:title="translate('will_be_backordered')"
								class="fas fa-exclamation-triangle text-warning text-small" />
						</template>
						<br>
						<span>
							<b>{{ price }}</b>
							<template v-if="exchange">
								&nbsp;
								<span class="text-small exchange-text">{{ exchangePrice }}</span>
							</template>
							<template v-if="bvs > 0">
								&nbsp;-&nbsp;
								<span class="text-medium"> {{ bvs }} BVs</span>
							</template>
						</span>
					</span>
				</div>
				<div
					:class="stackedLayout ? 'col-6' : 'col-3'"
					class="d-flex w-100 align-items-center">
					<quantity-select
						:init-qty="initQty"
						:select-limit="10"
						:max="99"
						include-zero
						:container-class="`w-100 ${stackedLayout ? 'text-right' : ''}`"
						sub-container-class="justify-content-end w-100"
						@change="$emit('change', $event)" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import QuantitySelect from '@/components/QuantitySelect';
import { Products, Purchase } from '@/translations';

export default {
	name: 'SimpleProduct',
	messages: [Products, Purchase],
	components: { QuantitySelect },
	props: {
		codeName: {
			type: String,
			required: true,
		},
		initQty: {
			type: Number,
			required: true,
		},
		thumbnail: {
			type: String,
			default: '',
		},
		stackedLayout: {
			type: Boolean,
			default: false,
		},
		backorderInfo: {
			type: Object,
			default: null,
		},
		price: {
			type: String,
			required: true,
		},
		exchange: {
			type: Boolean,
			default: false,
		},
		exchangePrice: {
			type: String,
			default: '',
		},
		bvs: {
			type: Number,
			default: 0,
		},
	},
};
</script>
