<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<b-alert
			:show="showBackorderInfo"
			variant="warning">
			<div v-html="translate('backorder_info')" />
			<template v-if="hasSelectedBackorderProducts">
				{{ translate('estimated_shipping_date', { date: '' }) }}
			</template>
		</b-alert>
		<!-- This is the list shown when there not are NFR products in the market -->
		<product-list
			v-if="true"
			:show-backorder-info="showBackorderInfo"
			:description="description"
			:configurable-products="getConfigurableProducts"
			:simple-products="getSimpleProducts"
			:selected-products.sync="selectedProducts"
			:show-bvs="showBvs"
			:loading="loading" />
		<!-- When there are NFR products in the market, this section is shown instead with products separated -->
		<template v-else>
			<b-tabs @input="handleTabChange">
				<b-tab>
					<template v-slot:title>
						<a class="list-group-item btn text-left text-gray-dark">
							{{ translate('otg_products') }}
						</a>
					</template>
					<product-list
						:show-backorder-info="showBackorderInfo"
						:description="description"
						:configurable-products="configurableProducts"
						:simple-products="simpleProducts"
						:selected-products.sync="selectedProducts"
						:loading="loading" />
				</b-tab>
				<b-tab>
					<template v-slot:title>
						<a class="list-group-item btn text-left text-gray-dark">
							{{ translate('nfr_products') }}
						</a>
					</template>
					<product-list
						:show-backorder-info="showBackorderInfo"
						:description="description"
						:configurable-products="nfrConfigurableProducts"
						:simple-products="nfrSimpleProducts"
						:selected-products.sync="selectedProducts"
						:loading="loading" />
				</b-tab>
			</b-tabs>
		</template>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="!!products.length"
			:class="loading ? 'mt-4' : ''"
			class="mx-auto" />
	</div>
</template>
<script>
import IsLoading from '@/components/Loading';
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import WindowSizes from '@/mixins/WindowSizes';
import { Products, Purchase, Promotions } from '@/translations';
import ProductList from './ProductList.vue';
import { currency } from '@/config/Filters';
import PackageCalculation from '@/mixins/PackageCalculation';

export default {
	name: 'ProductsSection',
	messages: [Products, Purchase, Promotions],
	components: {
		IsLoading,
		ProductList,
	},
	filters: {
		currency,
	},
	mixins: [ConfigurableProducts, WindowSizes, PackageCalculation],
	props: {
		loadingProducts: {
			type: Boolean,
			default: false,
		},
		products: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		discounts: {
			type: Array,
			default: () => [],
		},
		packVolume: {
			type: Number,
			default: 0,
		},
		selectedPack: {
			type: String,
			default: '',
		},
		isRegister: {
			type: Boolean,
			default: false,
		},
		sponsorId: {
			type: Number,
			default: 0,
		},
		loadingSave: {
			type: Boolean,
			default: false,
		},
		initialSelectedProducts: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			selectedProducts: this.initialSelectedProducts,
		};
	},
	computed: {
		showBvs() {
			return this.fullyCustomizable || this.fullyAmountCustomizable;
		},
		showBackorderInfo() {
			// eslint-disable-next-line camelcase
			return this.simpleProducts.some((item) => item.attributes.backorder_info?.will_backorder ?? false)
				|| this.configurableProducts.some((item) => {
					const children = !Array.isArray(item.attributes.children) ? Object.values(item.attributes.children) : item.attributes.children;
					// eslint-disable-next-line camelcase
					return children.some((child) => child.backorder_info?.will_backorder ?? false);
				});
		},
		hasSelectedBackorderProducts() {
			// TODO: Posponed
			return false;
		},
		currentProgress() {
			const amount = this.packPrice;
			let extraProductsAmount = 0;
			const productsWithDiscount = [];
			let productWithTenDiscount = 0;
			Object.entries(this.selectedProducts).forEach(([key, value]) => {
				let productPrice = 0;
				this.products.forEach((product) => {
					if (product.attributes.sku === key) {
						productPrice = product.attributes.price.min_price;
						return;
					}

					Object.keys(product.attributes.children).forEach((sku) => {
						if (sku === key) {
							productPrice = product.attributes.children[sku].price.min_price;
						}
					});
				});

				if (productPrice > 0 && productsWithDiscount.some((prefix) => key.startsWith(prefix))) {
					productWithTenDiscount += value.value;
				}
				extraProductsAmount += value.value * productPrice;
			});
			extraProductsAmount -= 10 * productWithTenDiscount;
			return amount + extraProductsAmount;
		},
		discount() {
			return [...this.discounts].reverse().find((item) => this.currentProgress >= item.min)?.discount ?? 0;
		},
		loading() {
			return this.loadingProducts;
			// || !Object.keys(this.selectedProducts).length || !this.products.length;
		},
		// Products
		simpleProducts() {
			return this.products.filter((product) => !product.attributes.has_configurations)
				.filter((product) => !product.attributes.is_nfr);
		},
		configurableProducts() {
			return this.products.filter((product) => product.attributes.has_configurations)
				.filter((product) => !product.attributes.is_nfr);
		},
		nfrSimpleProducts() {
			return this.products.filter((product) => !product.attributes.has_configurations)
				.filter((product) => product.attributes.is_nfr);
		},
		nfrConfigurableProducts() {
			return this.products.filter((product) => product.attributes.has_configurations)
				.filter((product) => product.attributes.is_nfr);
		},
		getConfigurableProducts() {
			if (this.nfrConfigurableProducts.length === 0) {
				return this.configurableProducts;
			}
			return this.nfrConfigurableProducts;
		},
		getSimpleProducts() {
			if (this.nfrSimpleProducts.length === 0) {
				return this.simpleProducts;
			}
			return this.nfrSimpleProducts;
		},
	},
	watch: {
		initialSelectedProducts: {
			handler() {
				this.selectedProducts = this.initialSelectedProducts;
			},
			deep: true,
		},
		selectedProducts: {
			handler() {
				this.$emit('update:selectedProducts', this.selectedProducts);
			},
			deep: true,
		},
	},
	methods: {
		handleTabChange() {
			// Do nothing
		},
	},
};
</script>
<style>
	/* Custom b-modal sizes https://github.com/bootstrap-vue/bootstrap-vue/issues/632#issuecomment-441719709 */
	.modal-lg-custom {
		max-width: 880px !important;
		width: 880px !important;
	}
	.modal-footer {
		justify-content: center !important;
	}
	.success-background {
		background-color: #7ebc5a !important;
	}
	.error-background {
		background-color: #AA3333 !important;
	}
</style>
